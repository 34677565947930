<template>
  <v-app>
    <!-- Start: Header -->
    <general-header
      :drawer="drawer"
      @updateDrawer="drawer = $event"
    ></general-header>
    <!-- End: Header -->
    <!-- Start: Navbar -->
    <general-navbar
      :drawer="drawer"
      @updateDrawer="drawer = $event"
    ></general-navbar>
    <!-- End: Navbar -->
    <!-- Start: Main -->
    <v-main class="grey lighten-3">
      <!-- Start: Breadcrumbs -->
      <v-breadcrumbs class="grey lighten-3 px-5 py-4" :items="breadcrumbs">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
      <!-- End: Breadcrumbs -->
      <slot></slot>
    </v-main>
    <!-- End: Main -->
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      drawer: null
    };
  },
  computed: {
    breadcrumbs() {
      return this.$route.meta.breadcrumbs;
    },
    ...mapGetters({
      rolesName: "ROLE/rolesName",
      invoices: "ROLE/invoices"
    })
  },
  created() {
    if (this.rolesName && this.rolesName.length === 0) {
      this.$store.dispatch("ROLE/getRolesInvoices");
    }
  }
};
</script>
